import React from 'react'
import Navbar from './Layout/Navbar/Navbar'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './Components/pages/Home/Home'
import './App.css'
import PageLayout from './Components/pages/Page/PageLayout'
import Blog from './Components/pages/Resources/Blog/Blog'
import Pricing from './Components/pages/Pricing/Pricing'
import AboutUs from './Components/pages/AboutUs/AboutUs'
import GrowPage from './Components/pages/Resources/Blog/GrowPage'
import Quantify from './Components/pages/Resources/Blog/Quantify'
import CustomerSuccess from './Components/pages/Resources/Blog/CustomerSuccess'
import DataControl from './Components/pages/Resources/Blog/DataControl'
import Strategy from './Components/pages/Resources/Blog/Strategy'
import RoiCs from './Components/pages/Resources/Blog/RoiCs'
import PageNotFound from './Components/pages/Page/PageNotFound'
import Career from './Components/pages/Career/Career'
import PrivacyPolicy from './Components/pages/Privacy/PrivacyPolicy'
 
 
export default function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path='/' element={<Navbar />}>
            <Route index element={<Home />} />
            <Route path='/customer360' element={<PageLayout id='customer360' page='solution' />} />
            <Route path='/prevent-churn' element={<PageLayout id='preventchurn' />} />
            <Route path='/customer-onboarding' element={<PageLayout id='customeronboarding' />} />
            <Route path='/high-touch-customer-success' element={<PageLayout id='hightouchcustomersuccess' />} />
            <Route path='/tech-touch-customer-success' element={<PageLayout id='techtouchcustomersuccess' />} />
            <Route path='/customer-expansion' element={<PageLayout id='customerexpansion' />} />
            <Route path='/renewal-management' element={<PageLayout id='renewalmanagement' />} />
            <Route path='/risk-management' element={<PageLayout id='riskmanagement' />} />
            <Route path='/customer-health' element={<PageLayout id='customerhealth' />} />
            <Route path='/success-planning' element={<PageLayout id='successplanning' />} />
            <Route path='/product-adoption' element={<PageLayout id='productadoption' />} />
            <Route path='/playbook' element={<PageLayout id='playbook' />} />
            <Route path='/customer-centric-solution' element={<PageLayout id='customercentricsolution' />} />
            <Route path='/customer-collaboration' element={<PageLayout id='customercollaboration' />} />
            <Route path='/stakeholder-alignment' element={<PageLayout id='stakeholderalignment' />} />
            <Route path='/voice-of-customer' element={<PageLayout id='voiceofcustomer' />} />
            <Route path='/ai-email-generator' element={<PageLayout id='aiemailgenerator' />} />
            <Route path='/data-integration' element={<PageLayout id='dataintegration' />} />
            <Route path='/external-process-automation' element={<PageLayout id='externalprocessautomation' />} />
            <Route path='/ui-builder' element={<PageLayout id='uibuilder' />} />
            <Route path='/triggered-alerts' element={<PageLayout id='triggeredalerts' />} />
            <Route path='/success-plan' element={<PageLayout id='successplan' />} />
            <Route path='/custom-data-attribute' element={<PageLayout id='customdataattribute' />} />
            <Route path='/calendar-email-integration' element={<PageLayout id='calendaremailintegration' />} />
            <Route path='/kpis' element={<PageLayout id='kpis' />} />
            <Route path='/sponsor-tracking' element={<PageLayout id='sponsortracking' />} />
            <Route path='/customer-success-manager' element={<PageLayout id='customersuccessmanager' />} />
            <Route path='/executives-cxo' element={<PageLayout id='executives' />} />
            <Route path='/customer-success-operations' element={<PageLayout id='customersuccessoperation' />} />
            <Route path='/grow-your-business-by-growing-customer-numbers' element={<GrowPage />} />
            <Route path='/how-to-quantify-customer-success' element={<Quantify />} />
            <Route path='/who-should-own-customer-success' element={<CustomerSuccess />} />
            <Route path='/getting-full-roi-from-your-customer-success-investment-3' element={<RoiCs />} />
            <Route path='/controlling-your-customer-success-data-in-the-cloud' element={<DataControl />} />
            <Route path='/4-effective-customer-success-strategies-for-2019' element={<Strategy />} />
            <Route path='/career' element={<Career />} />
            <Route path='/blog' element={<Blog />} />
            <Route path='/pricing' element={<Pricing />} />
            <Route path='/about' element={<AboutUs />} />
            <Route path='/privacypolicy' element={<PrivacyPolicy />} />
            <Route path='*' element={<PageNotFound />} />
          </Route> 
        </Routes>
      </Router>
    </div>
  )
}