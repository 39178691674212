import React, { useRef, useState } from 'react';
import ContactBanner from '../../../assets/contact-banner.jpg';
import { GrClose } from "react-icons/gr";

export default function RequestDemo({ onClose }) {
  const form = useRef();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    companyname: '',
    role: '',
    requirement: ''
  });
  const [formErrors, setFormErrors] = useState({
    name: false,
    email: false,
    companyname: false,
    role: false,
    requirement: false
  });
  const [submissionStatus, setSubmissionStatus] = useState('');
  const [submissionStatusError, setSubmissionStatusError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Email validation regex pattern
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the email is valid and update formErrors accordingly
    setFormErrors({ ...formErrors, [name]: name === 'email' ? !emailPattern.test(value) : !value.trim() });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const allFieldsCaptured = Object.values(formData).every(value => value.trim() !== '');
    if (allFieldsCaptured && !formErrors.email) {
      setSubmissionStatus('Form submitted successfully');
      setTimeout(() => {
        setSubmissionStatus('');
        setFormData({
          name: '',
          email: '',
          companyname: '',
          role: '',
          requirement: ''
        });
      }, 2000);// Further actions on successful submission
    } else {
      setSubmissionStatusError('Please fill in all fields correctly');
      setTimeout(() => {
        setSubmissionStatusError('');
      }, 2000); // Remove error message after 2 seconds
    }
  };

  const { name, email, companyname } = formData;

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-40 z-50 flex items-center justify-center shadow-md lg:p-5 xl:bg-cover xl:bg-center bg-custom "  >
        <div className="max-w-screen-xs relative bg-white lg:py-20  lg:px-32 rounded-2xl shadow-lg lg:w-[73%] ">
          <button
            className="absolute top-2 right-2 p-5 cursor-pointer text-xl"
            onClick={onClose}
          >
            <GrClose />
          </button>
          <div className="flex flex-col  items-center justify-center lg:flex-row w-full gap-20 ">
            <div className="hidden xl:block ">
              <img src={ContactBanner} className="w-full h-auto rounded-lg" alt="Contact Banner" />
            </div>
            <div className="flex-1  flex-row ">
              <form className=" grid grid-col gap-4   p-10 lg:h-[50vh]" ref={form} onSubmit={handleSubmit}>
                <h1 className="text-2xl lg:text-4xl font-bold">Contact Us</h1>
                <div className='flex flex-col gap-2'>
                  <label >Full Name <span className='text-red-500 text-xl '>*</span></label>
                  <input
                    className={`w-full border border-gray-400 p-3 border-[1px] rounded-[6px] hover:border-blue-500 focus:border-blue-500 focus:outline-none focus-within:border-blue-500 ${formErrors.name ? 'border-red-500' : ''}`}
                    placeholder="Name" name='name' value={name} onChange={handleChange}
                  />
                  {formErrors.name && <span className="text-red-500 text-sm">Please enter your name</span>}
                </div>
                <div className='flex flex-col gap-2'>
                  <label >E-Mail <span className='text-red-500 text-xl '>*</span></label>
                  <input
                    type='email'
                    className={`w-full border border-gray-400 p-3 border-[1px] rounded-[6px] hover:border-blue-500 focus:border-blue-500 focus:outline-none focus-within:border-blue-500 ${formErrors.email ? 'border-red-500' : ''}`}
                    placeholder="Work E-mail " name='email' value={email} onChange={handleChange}
                  />
                  {formErrors.email && <span className="text-red-500 text-sm">Please enter a valid email address</span>}
                </div>
                <div className='flex flex-col gap-2'>
                  <label >Company Name <span className='text-red-500 text-xl '>*</span></label>
                  <input
                    className={`w-full border border-gray-400 p-3 border-[1px] rounded-[6px] hover:border-blue-500 focus:border-blue-500 focus:outline-none focus-within:border-blue-500 ${formErrors.companyname ? 'border-red-500' : ''}`}
                    placeholder="Company Name " name='companyname' value={companyname} onChange={handleChange}
                  />
                  {formErrors.companyname && <span className="text-red-500 text-sm">Please enter your company name</span>}
                </div>
                <button
                  className={`bg-blueColor text-white p-1 rounded-[4px] text-xl`}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      {submissionStatusError && (
        <div className="fixed inset-x-0 bottom-0 flex items-center justify-center z-50">
          <div className="bg-red-500 text-white p-3 rounded">
            {submissionStatusError}
          </div>
        </div>
      )}
      {submissionStatus && (
        <div className="fixed inset-x-0 bottom-0 flex items-center justify-center z-50">
          <div className="5 text-white p-3 rounded">
            {submissionStatus}
          </div>
        </div>
      )}
    </>
  );
}
